// import React, { useEffect, useState } from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import Login from "./pages/Login";
// import Dashboard from "./pages/DashboardLayout";
// import { getToken, onMessage } from "firebase/messaging";
// import { messaging } from "./Components/firebase/firebaseUtiles";

// import { Button, Divider, notification, Space } from "antd";
// const App = () => {
//   const [api, contextHolder] = notification.useNotification();
//   onMessage(messaging, (payload) => {
//     api.info({
//       message: payload?.notification?.title,
//       description: payload?.notification?.body,
//     });
//   });
//   return (
//     <Router>
//       {contextHolder}
//       <Routes>
//         <Route path="/" element={<Navigate to="/login" />} />
//         <Route path="/login" element={<Login />} />
//         <Route path="/dashboard/*" element={<Dashboard />} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;


import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/DashboardLayout";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./Components/firebase/firebaseUtiles";

import { Button, Divider, notification } from "antd";

const App = () => {
  const [api, contextHolder] = notification.useNotification();
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    // Listen for new messages
    onMessage(messaging, (payload) => {
      api.info({
        message: payload?.notification?.title,
        description: payload?.notification?.body,
      });
      // Increment the notification count
      setNotificationCount((prev) => prev + 1);
    });
  }, [api]);

  // Handler to clear the notification count
  const clearNotificationCount = () => {
    setNotificationCount(0);
  };

  return (
    <Router>
      {contextHolder}
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        {/* Pass notificationCount and clearNotificationCount as props */}
        <Route
          path="/dashboard/*"
          element={
            <Dashboard
              notificationCount={notificationCount}
              clearNotificationCount={clearNotificationCount}
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
