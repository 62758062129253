
// // import React from 'react';
// // import { Routes, Route } from 'react-router-dom';
// // import Home from '../pages/Home'
// // const LayoutRoutes = () => {
// //   return (
// //     <Routes>
// //       <Route path="/" element={<Home/>} />
// //       <Route path="History" element={<div>Content for Nav 2</div>} />
// //       <Route path="Profile" element={<div>Content for Nav 3</div>} />
// //     </Routes>
// //   );
// // };

// // export default LayoutRoutes;


// import React from 'react';
// import { Routes, Route } from 'react-router-dom';
// import Home from '../pages/Home'
// // import History from '../pages/History'
// const LayoutRoutes = () => {
//   return (
//     <Routes>
//       <Route path="/" element={<Home/>} />
//       <Route path="History" element={<div>History</div>} />
//       <Route path="Notifications" element={<div>Notifications</div>} />
//       <Route path="ServiceHistory" element={<div>ServiceHistory</div>} />
//       <Route path="AboutUs" element={<div>AboutUs</div>} />
//       <Route path="CustomerService" element={<div>CustomerService</div>} />
//       <Route path="ChangePassword" element={<div>ChangePassword</div>} />
//       <Route path="Profile" element={<div>Profile</div>} />
//     </Routes>
//   );
// };
 
// export default LayoutRoutes;


// import React from 'react';
// import { Routes, Route } from 'react-router-dom';
// import Home from '../pages/Home'
// import History from '../pages/History';
// import Notification from '../pages/Notification'
// import Servicehistory from '../pages/Servicehistory';
// import Aboutus from '../pages/Aboutus';
// import Customerservice from '../pages/Customerservice';
// import Changepassword from '../pages/Changepassword';
// import Profile from '../pages/Profile';
// import UserProfile from '../pages/UserProfile';
// // import History from '../pages/History'
// const LayoutRoutes = () => {
//   return (
//     <Routes>
//       <Route path="/" element={<Home/>} />
//       <Route path="History" element={<History />} />
//       <Route path="Notifications" element={<Notification />} />
//       <Route path="ServiceHistory" element={<Servicehistory />} />
//       <Route path="AboutUs" element={<Aboutus />} />
//       <Route path="CustomerService" element={<Customerservice />} />
//       <Route path="ChangePassword" element={<Changepassword />} />
//       <Route path="Profile" element={<Profile />} />
//       <Route path="User" element={<UserProfile />} />
//     </Routes>
//   );
// };
 
// export default LayoutRoutes;



import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home'
import History from '../pages/History';
import Notification from '../pages/Notification'
import Servicehistory from '../pages/Servicehistory';
import Aboutus from '../pages/Aboutus';
import Customerservice from '../pages/Customerservice';
import Changepassword from '../pages/Changepassword';
import Profile from '../pages/Profile';
import UserProfile from '../pages/UserProfile';
import Manageuser from '../pages/Manageuser';
// import History from '../pages/History'
const LayoutRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="History" element={<History />} />
      <Route path="Notifications" element={<Notification />} />
      <Route path="ServiceHistory" element={<Servicehistory />} />
      <Route path="Manageuser" element={<Manageuser />} />
      <Route path="AboutUs" element={<Aboutus />} />
      <Route path="CustomerService" element={<Customerservice />} />
      <Route path="ChangePassword" element={<Changepassword />} />
      <Route path="Profile" element={<Profile />} />
      {/* <Route path="Users" element={<UserProfile />} /> */}
      <Route path="Users/:userId" element={<UserProfile />}/>
       
      
    </Routes>
  );
};
 
export default LayoutRoutes;