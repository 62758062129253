import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import {
  LinkOutlined,
  AppstoreOutlined,
  AppstoreFilled,
} from "@ant-design/icons";
import { Input, Flex, Button, message, notification } from "antd";
import { Spin } from "antd";
import { Modal } from "antd";
import "./ShoppingCollage.css";
import CollageComponent from "../cards/CollageComponent";
import ImageUploadAndPreview from "./ImageUploadAndPreview";
import Collage from "../Collage/Collage";
import CapsuleWardrobe1 from "../Collage/CapsuleCollage";
import PeiceCollage from "../Collage/PieceCollage";
import CollageShoppingComponent from "../cards/CollageShoppingComponent";
import Item from "antd/es/list/Item";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const CapsuleCollage = ({ SelectUserData }) => {
  const [tabCountMap, setTabCountMap] = useState({});
  const [optionsList, setOptionsList] = useState([]);
  const [tabCounts, setTabCounts] = useState({ pieces: 0, looks: 0 });
  const [UserShoppingCollageData, setUserShoppingCollageData] = useState([]);
  const [linkInputs, setLinkInputs] = useState(Array(8).fill(""));
  const [disableButton, setdisableButton] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [requiredLinks, setRequiredLinks] = useState(0);
  const [dispalycollagecretaion, setdispalycollagecretaion] = useState(false);
  const [highlightedIndexes, setHighlightedIndexes] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [product, setproduct] = useState([]);
  const [productimagelink, setproductimagelink] = useState([]);
  const [dispalProduct, setdispalProduct] = useState(false);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [loading, setloading] = useState(false);
  const [axis, setaxis] = useState(0);
  const [sendfileDataList, setsendfileDataList] = useState([]);
  const [displaLinkData, setdisplaLinkData] = useState(true);
  const [COLLAGE_DESCRIPTION, setCOLLAGE_DESCRIPTION] = useState("");
  const [TemplateSelection, setTemplateSelection] = useState(0);
  const [uploadDragData, setUploadDragData] = useState(null); // Track the item to be edited
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [UserShoppingCollageData1, setUserShoppingCollageData1] = useState([]);
  const fcmToken = sessionStorage.getItem("fcmToken");
  const [data, setData] = useState([]);
  const [selectedTabName, setSelectedTabName] = useState(null);

  const [selectedTab, setSelectedTab] = useState("");

  const [activeTab, setActiveTab] = useState("1");
  const [error, setError] = useState(null);

  const condition1 = UserShoppingCollageData.length === 1 && disableButton && activeTab === "1";
  const condition2 = UserShoppingCollageData.length === 11 && disableButton && activeTab === "2";
  const condition3 = UserShoppingCollageData.length === 1 && disableButton && activeTab === "1" && SelectUserData.status == 2;
  const condition4 = UserShoppingCollageData.length === 11 && disableButton && activeTab === "2" && SelectUserData.status == 2;
  
  // Button is disabled only if all conditions are false
  const isDisabled = !(condition1 || condition2 || condition3 || condition4);
  function modifyUrls(urls) {
    // const baseUrl = ` "https://skandaqa.stylz.in"`;
    const baseUrl = ` ${config.imageUrl}`;
    return urls.map((url) => {
      if (url.startsWith(baseUrl)) {
        return url.replace(baseUrl, ""); // Remove the base URL
      }
      return url; // If it doesn't match, return the original URL
    });
  }

  //   const handleTabChange1 = (tabName) => {
  //     setSelectedTab(tabName); // Update local state
  //     if (onTabChange) {
  //       onTabChange(tabName); // Pass the tabName to parent
  //     }
  //   };
  const CollageNotifyBackend = async () => {
    try {
      const response = await axios.post(
        `${config.baseURL}notificationService`,
        new URLSearchParams({
          USER_ID: SelectUserData?.user_id.toString(),
          NOTI_TITLE: "Stylz",
          NOTI_BODY: "Your lookbook is ready",
          APP_TYPE: "stylz",
          NOTI_STATUS: "0",
          STYLIST_ID: SelectUserData?.STYLIST_ID?.toString(),
          SERVICE_ID: SelectUserData?.SERVICE_SHORT_CODE.toString(),
          USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      // console.log(response);
      if (response?.data?.message === "success") {
      }
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  // for edit
  const showEditConfirmation = (item) => {
    setEditItem(item); // Store the item to edit
    // handleModalOk(item);
    setIsEditModalVisible(true); // Show the modal
  };
  //for edit
  const handleModalOk = () => {
    setIsEditModalVisible(false); // Close the modal
    // Proceed with the edit logic
    // console.log("Editing item:", editItem);
    // setEditItem(item);
    // Add your edit logic here
  };
  //fot edit
  const handleModalCancel = () => {
    setIsEditModalVisible(false); // Close the modal without editing
    setEditItem(null); // Reset the edit item
  };

  // for upload collage
  const handleSubmitButtonClick = () => {
    // Show confirmation modal when "Upload Collage" is clicked
    setIsModalVisible(true);
  };
  // for upload collage
  const handleModalOk1 = () => {
    setIsModalVisible(false); // Close the modal
    SubmmitCollage(); // Proceed with submitting the collage
  };
  // for upload collage
  const handleModalCancel1 = () => {
    setIsModalVisible(false); // Close the modal without submitting
  };

  // Show the delete confirmation modal
  const showDeleteConfirmation = (item) => {
    setSelectedItem(item); // Set the selected item for deletion
    setIsDeleteModalVisible(true); // Open the delete modal
  };

  // Handle OK button for delete confirmation and proceed with deletion
  const handleDeleteModalOk = () => {
    if (selectedItem) {
      setIsDeleteModalVisible(false); // Close the modal
      // console.log("Item deleted:", selectedItem); // Add your delete logic here
      // Proceed with your delete logic (e.g., call delete API or remove item from state)
      handleDelete(selectedItem);
      // setUserShoppingCollageData1((prevData) => prevData.filter((item) => item !== selectedItem));
    }
    setSelectedItem(null); // Reset the selected item after deletion
  };

  // Handle Cancel button for delete confirmation
  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false); // Close the delete modal
    setSelectedItem(null); // Reset the selected item
  };
  const CollageNotifyAdmin = async () => {
    // console.log(data, "sidjshisil....");
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyAdmin`,
        new URLSearchParams({
          title: `Stylz :- ${SelectUserData.stylist_name} `,
          body:
            SelectUserData.status == 2
              ? `${SelectUserData.first_name} :- Extra lookbook is ready `
              : `${SelectUserData.first_name} :- lookbook is ready `,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 201) {
        console.log(response.data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const CollageUserNotify = async () => {
    // // console.log(data, "sidjshisil....");
   await CollageNotifyAdmin();
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyUsers`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Skanda Stylz",
          body: "Your lookbook is ready ",
          service_id: SelectUserData.SERVICE_SHORT_CODE,
          orderId: SelectUserData.user_service_id,
          amount: '',
          transaction_id: ''
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      // console.log(response);
      if (response?.status === 201) {
        // console.log(response.data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };

  function extractUrls(data) {
    try {
      // Check if data is a string; if so, parse it into an object
      const parsedData = typeof data === "string" ? JSON.parse(data) : data;

      // Extract the 'url' field from each object in the array
      const urls = parsedData.map((item) => {
        if (item.url) {
          return item.url; // Return the URL if it exists
        } else {
          return item; // Return the entire item if URL does not exist
        }
      });

      return urls;
    } catch (error) {
      console.error("Invalid data provided", error);
      return [];
    }
  }

  const handleTabChange = (tabName) => {
    // console.log("Selected Tab:", tabName);
    setSelectedTab(tabName); // Update selectedTab with the new tab name
    getUserShoppingCollageData(); // Fetch data based on the new eventType (selectedTab)
  };

  const handleTabChange1 = (key) => {
    setActiveTab(key);
    // console.log(`Selected tab: ${key}`);
  };

  const UploadSingleColllage = async (
    path,
    DESCRIPTION,
    fileDataList,
    fileList,
    imageId = null
  ) => {
    // console.log(product, "Product........");

    // console.log(`Selected Tab 12345567: ${selectedTab}`);
    // const selectedImagesList = JSON.parse(
    //   sessionStorage.getItem("linkSelectedData")
    // );
    // const filteredLinks = linkInputs.filter((link) => link.trim() !== "");
    // const matchedProducts = matchImagesToProducts(
    //   modifyUrls(productimagelink),
    //   selectedImagesList
    // );

    // console.log(imageId, "fileDataList1.....");
    // console.log("SERVICE_ID:", SelectUserData.SERVICE_SHORT_CODE);
    // console.log(path, DESCRIPTION, fileDataList, fileList, "fileDataList.....");
    try {
      const formData = new FormData();
      // console.log(product, "product........");
      if (path) {
        formData.append("picture", path);
      }

      console.log("Selected Tab in capsule Tab:", selectedTab);
      // console.log(fileDataList, "sendfileDataList.........sendfileDataList");

      // const linkSelectedData=sessionStorage.getItem('linkSelectedData');
      // console.log(
      //   path,
      //   "image paths",
      //   productimagelink,
      //   modifyUrls(productimagelink),
      //   fileDataList,
      //   extractUrls(fileDataList)
      // );
      formData.append(
        "data",
        JSON.stringify({
          USER_ID: SelectUserData.user_id.toString(),
          STYLIST_ID: SelectUserData.stylist_id,
          IMAGE_ID: imageId,
          USER_SERVICE_ID: SelectUserData.user_service_id,
          IMAGE_NAME: path.name,
          IMAGE_LIST: modifyUrls(fileList),
          LINK_LIST: `[${extractUrls(fileDataList).join(", ")}]`,
          UPLOADED_BY: LoginUser.USER_ID,
          SERVICE_ID: SelectUserData.SERVICE_SHORT_CODE,
          AXIS: 0,
          SCRAPE_DATA: "",
          JsonResponse: fileDataList,
          COLLAGE_DESCRIPTION: DESCRIPTION,
          EVENT_TYPE: activeTab === '2' ? selectedTab : "Pieces",
          CAPSULE_TYPE: activeTab === "1" ? "Pieces" : "Looks",
        })
      );

      const response = await axios.post(
        `${config.baseURL}shoppingCollageList`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      // console.log(response);
      if (response?.status === 200) {
        // console.log(response, "lsmdlnlsnlnl....colaage.......");
        getUserShoppingCollageData();
        setloading(false);
        setdispalycollagecretaion(false);
        setLinkInputs(Array(8).fill(""));
        setproductimagelink([]);
        setproduct([]);
        setEditItem(null);
        message.success(" Collage Created successfully");
      }
      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
      throw error;
    }
  };
  const getUseronlineCollageData = async () => {
    setdisableButton(false);
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_SHORT_CODE
        }/${SelectUserData.USER_SERVICE_ID}/${SelectUserData.stylist_id}/${1}`
      );
      // console.log(response, "get collage data");
      if (response?.data?.recordset) {
        console.log(response.data.recordset, "get collage data");
        setUserShoppingCollageData(response.data.recordset);

        const filteredData = response.data.recordset.filter(
          (item) => item.EVENT_TYPE === selectedTab
        );

        // console.log("Filtered Data:", filteredData);

        if (response.data.recordset.length !== 0) {
          // console.log("axis....", response.data.recordset.length);
          setaxis(1);
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
    setdisableButton(true);
  };
  const getUserShoppingCollageData = async () => {
    setdisableButton(false);
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_SHORT_CODE
        }/${SelectUserData.USER_SERVICE_ID}/${SelectUserData.stylist_id}/${0}`
      );
      // // console.log(response)
      if (response?.data?.recordset) {
        // console.log(response.data.recordset, "get collage data");

        setUserShoppingCollageData(response.data.recordset);
        const filteredData = response.data.recordset.filter(
          (item) => item.EVENT_TYPE === selectedTab
        );

        // console.log("Filtered Data:", filteredData);

        if (response.data.recordset.length === 0) {
          // console.log("0....");

          await getUseronlineCollageData();
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }

    setdisableButton(true);
  };

  const getUserShoppingCollageDataTabs = async () => {
    try {
      const response = await axios.get(
        `${config.baseURL}getWeddingTabs/${SelectUserData.user_id}/WEDDING_ASSISTANCE`
      );
      if (response?.data?.recordset) {
        // console.log(response.data.recordset, "get collage tabs");

        // Extract the 'USER_ANSWERS' and parse it to get 'optionText' values
        const optionsList = response.data.recordset
          .map((item) => {
            const userAnswers = item.USER_ANSWERS
              ? JSON.parse(item.USER_ANSWERS)
              : [];
            // Extracting the 'optionText' for each answer in USER_ANSWERS
            return userAnswers.map((answer) => answer.optionText).flat(); // This will return an array of options
          })
          .flat();

        setOptionsList(optionsList);
        // console.log("optiosnlistinshooping", optionsList);
        // console.log("optiosnlistinshoopinglength", optionsList.length);
        // console.log(
        //   "optiosnlistinshoopinglengthmultiple",
        //   optionsList.length * 3
        // );

        // Output the optionsList
        // console.log("Extracted options:", optionsList);
        setData(response.data.recordset);
      }
    } catch (error) {
      setError(error.message);
      console.error("Error fetching report details:", error);
    }
  };

  // const getUserCapsuleCollageDataTabs = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${config.baseURL}getcapsuleTabs/${SelectUserData.user_id}`
  //     );
  //     if (response?.data?.recordset) {
  //       // console.log(response.data.recordset, "get collage tabs");
  
  //       // Extract the 'USER_ANSWERS' and parse it to get 'optionText' values
  //       const optionsList = response.data.recordset.reduce((acc, item) => {
  //         const userAnswers = item.USER_ANSWERS
  //           ? JSON.parse(item.USER_ANSWERS)
  //           : [];
  //         // Extracting the 'optionText' for each answer in USER_ANSWERS
  //         const optionTexts = userAnswers.map((answer) => answer.optionText);
  //         // Adding unique optionTexts to the accumulator (acc)
  //         acc.push(...optionTexts);
  //         return acc;
  //       }, []);
  
  //       // Remove duplicates from the optionsList (if any)
  //       const uniqueOptionsList = [...new Set(optionsList)];

  //       const tabCounts = response.data.recordset.reduce((acc, item) => {
  //         acc[item.TAB_NAME] = item.COUNT; // Assuming the response contains a 'TAB_NAME' and 'COUNT'
  //         return acc;
  //       }, {});
  
  //       setOptionsList(uniqueOptionsList);  // Set the list of options
  //       setSelectedTab(uniqueOptionsList?.[0]); // Set the first tab as selected
  //       // console.log("optionslistincapsule", uniqueOptionsList);
  //       // Output the unique optionsList
  //       // console.log("Extracted options in collage:", uniqueOptionsList);
  //       setData(response.data.recordset);
  //     }
  //   } catch (error) {
  //     setError(error.message);
  //     console.error("Error fetching report details:", error);
  //   }
  // };

  const getUserCapsuleCollageDataTabs = async () => {
    try {
      const response = await axios.get(
        `${config.baseURL}getcapsuleTabs/${SelectUserData.user_id}`
      );
      if (response?.data?.recordset) {
        console.log(response.data.recordset, "get collage tabs");
  
        // Initialize a map to store counts of each optionText
        const newTabCountMap = {};
  
        // Extract the 'USER_ANSWERS' and parse it to get 'optionText' and 'count' values
        const optionsList = response.data.recordset.reduce((acc, item) => {
          const userAnswers = item.USER_ANSWERS
            ? JSON.parse(item.USER_ANSWERS)
            : [];
          
          // Loop through each answer in USER_ANSWERS
// Loop through each answer in USER_ANSWERS
userAnswers.forEach((answer) => {
  const optionText = answer.optionText;
  console.log("optionTextMap1222", optionText, answer)
  const count = answer.count || 0; // Default count to 0 if not present

  // Skip if optionText is invalid (undefined or empty)
  if (optionText) {
    console.log("optionTextincrementing", optionText)
    if(!tabCountMap[optionText])
    tabCountMap[optionText] = (tabCountMap[optionText] || 0) + Number(count);
  } else {
    // console.log('Skipping invalid optionText:', optionText); // Log the invalid case
  }
});

          
          console.log("Final tabCountMap:", newTabCountMap);

          // Collect unique optionTexts (without duplicates)
          const optionTexts = userAnswers.map((answer) => answer.optionText);
          acc.push(...optionTexts);
          return acc;
        }, []);
  
        // Remove duplicates from the optionsList (if any)
        const uniqueOptionsList = [...new Set(optionsList)];
  
        setOptionsList(uniqueOptionsList); // Set the list of options
        setSelectedTab(uniqueOptionsList?.[0]); // Set the first tab as selected
        console.log("optionslistincapsule", uniqueOptionsList);
  
        // Log the extracted option counts for each tab
        console.log("Tab counts:", tabCountMap);
  
        // Set the tab counts in the state
        setTabCounts(newTabCountMap);
  
        // Set the record data to the state
        setData(response.data.recordset);
      }
    } catch (error) {
      setError(error.message);
      console.error("Error fetching report details:", error);
    }
  };
  
  

  // const getUserCapsuleCollageDataTabs = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${config.baseURL}getcapsuleTabs/${SelectUserData.user_id}`
  //     );
  //     if (response?.data?.recordset) {
  //       // console.log(response.data.recordset, "get collage tabs");

  //       // Extract the 'USER_ANSWERS' and parse it to get 'optionText' values
  //       const optionsList = response.data.recordset.map((item) => {
  //         const userAnswers = item.USER_ANSWERS
  //           ? JSON.parse(item.USER_ANSWERS)
  //           : [];
  //         // Extracting the 'optionText' for each answer in USER_ANSWERS
  //         return userAnswers.map((answer) => answer.optionText).join(", "); // Joining option texts if there are multiple
  //       });

  //       setOptionsList(optionsList);
  //       setSelectedTab(optionsList?.[0]);
  //       // console.log("optiosnlistincapsule", optionsList);
  //       // Output the optionsList
  //       // console.log("Extracted options in collage:", optionsList);
  //       setData(response.data.recordset);
  //     }
  //   } catch (error) {
  //     setError(error.message);
  //     console.error("Error fetching report details:", error);
  //   }
  // };

  const CreateCollageUseLink = async (urls) => {
    // console.log([urls], "isdjifljsdiopmp");
    try {
      const params = new URLSearchParams();

      // Add each URL to the 'urls' key in the params
      urls.filter(Boolean).forEach((url) => {
        params.append("urls", url);
      });
      const response = await axios.post(`${config.baseURL}scrape`, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });
      // console.log(response, "kdsnknskdnk....out");

      // Handle the response as needed
      if (response?.data) {
        const links = response.data.map((data) => data.imageURL);
        // console.log(response.data, "kdsnknskdnk....out", links);
        setproduct(response.data);
        setproductimagelink(links);
        setloading(false);
      }

      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };

  const handleTemplateSelection = (template) => {
    setRequiredLinks(template);
  };
  const handleTemplateSelectionsubmmit = (template) => {
    setTemplateSelection(template);
    if (UserShoppingCollageData.length < 3) {
      setRequiredLinks(template);

      // Determine which indexes should be highlighted
      const requiredIndexes = Array.from({ length: template }, (_, i) => i);
      const providedLinksCount = linkInputs
        .slice(0, template)
        .filter((link) => link.trim() !== "").length;

      // // console.log("Current Link Inputs:", linkInputs);
      // console.log(providedLinksCount, template);
      // if (providedLinksCount < template) {
      //   setHighlightedIndexes(requiredIndexes);
      //   // setValidationError(`Please provide ${template} file links.`);
      //   message.error(`Please provide ${template} file links.`);
      //   setdispalProduct(false);
      // } else {
      setHighlightedIndexes([]);
      setValidationError("");
      CreateCollageUseLink(linkInputs);
      setloading(true);
      setdispalProduct(true);
      // // console.log(linkInputs.filter(Boolean), "linkss");
      // }
    } else {
      message.error("Only 3 collages can be created. Your limit reached.");
    }
  };
  const CollageNotify = async () => {
    // // console.log(data, "sidjshisil....");
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyStylist`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Skanda Stylz",
          body: "Your lookbook is ready ",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      // console.log(response);
      if (response?.status === 201) {
        // console.log(response.data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const updateCollage = async () => {
    // try {
    //   const params = {
    //     USER_ID: SelectUserData.user_id.toString(),

    //     COLLAGE: "Stylist completed your outfit",
    //     SERVICE_ID: SelectUserData.SERVICE_SHORT_CODE.toString(),
    //     CUSTOMER_ID: SelectUserData.user_id.toString(),
    //     STYLIST_TOKEN: fcmToken,
    //     CUSTOMER_TOKEN: SelectUserData.fcm_token,
    //   };

    //   const response = await axios.post(
    //     `${config.baseURL}collageImagesList`,
    //     params,
    //     {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    //       },
    //     }
    //   );
    //   // console.log(response, "kdsnknskdnk....out");

    //   if (response?.data) {
    //     notification.info({
    //       message: "Success",
    //       description: "I have sent the collage to the user.",
    //     });
    //     await CollageNotify();
    //     CollageNotifyBackend();
    //     CollageUserNotify();
    //     // console.log(response.data, "kdsnknskdnk....out");
    //     setaxis(1);
    //     setEditItem(null);
    //     await getUseronlineCollageData();
    //   }

    //   return response.data;
    // } catch (error) {
    //   console.error("Error signing in:", error);
    // }

    await CollageNotify();
        CollageNotifyBackend();
        CollageUserNotify();
        setaxis(1);
        setEditItem(null);
        await getUseronlineCollageData();
  };
  const collageImagesList = async () => {
    try {
      const params = {
        USER_ID: SelectUserData.user_id.toString(),
        STYLIST_ID: SelectUserData.stylist_id,
        USER_SERVICE_ID: SelectUserData.user_service_id,
      };

      const response = await axios.post(
        `${config.baseURL}collageImagesList`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      // console.log(response, "kdsnknskdnk....out");

      if (response?.data) {
        // console.log(response.data, "kdsnknskdnk....out");
        await updateCollage();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

 


  useEffect(() => {
    // getUserShoppingCollageData(SelectUserData?.user_id);
    // console.log(SelectUserData);
    const defaultTab = UserShoppingCollageData?.[0]?.EVENT_TYPE; // Get EVENT_TYPE of the first item
    if (defaultTab) {
      setSelectedTab(defaultTab); // Set the first tab as default
      getUserShoppingCollageData(); // Fetch data for the default tab
    }
     
    // getUserShoppingCollageDataTabs();
    handleTemplateSelection(5);
    if (uploadDragData) {
      // console.log("uploadDragImageList data outside callback:", uploadDragData);
    }
  }, [SelectUserData, loading, uploadDragData]);


useEffect(()=>{
  getUserCapsuleCollageDataTabs();
  console.log("calling")
},[])
  
    useEffect(() => {
       
    }, [activeTab]);
  const uploadCreatedCollage = async (
    file,
    DESCRIPTION,
    fileDataList,
    fileList,
    imageId
  ) => {
    // console.log(file, ";;;;;.......................");
    // console.log(imageId, "fileDataList333333.....");
    await UploadSingleColllage(
      file,
      DESCRIPTION,
      fileDataList,
      fileList,
      imageId
    );
  };
  const handleDelete = async (record) => {
    // console.log(record, "kisikjdnjk");
    try {
      const body = {
        USER_ID: record.USER_ID.toString(),
        IMAGE_ID: record.IMAGE_ID.toString(),
      };
      const response = await axios.post(
        `${config.baseURL}deleteCollage`,
        body,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      // console.log(response);
      if (response?.status === 200) {
        await getUserShoppingCollageData();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const SubmmitCollage = async () => {
    await collageImagesList();
  };
  const CreateCollageButton = () => {
    // console.log(TemplateSelection, "TemplateSelection");
    handleTemplateSelection(TemplateSelection >= 5 ? TemplateSelection : 0);
  };
  const setuploadDragImageList = () => {};
  // console.log(editItem, "dishioshoif...");
  return (
    <div>
      <div
        className="tab-buttons"
        style={{ display: "flex", gap: "10px", marginBottom: "20px" }}
      >
{optionsList && optionsList.length > 0 &&
  optionsList.map((tabName, index) => {
    console.log(`Rendering tab: ${tabName}`);

    // Ensure tabCountMap is constructed properly
    console.log("Tab Count Map:", tabCountMap);

    // Get the tab count from tabCountMap, default to 0 if the tabName doesn't exist in the map
    const tabCount = tabCountMap[tabName];
    console.log(`Rendering tab: ${tabName}, Count: ${tabCount}`);

    if (tabCount === undefined || tabCount === 0) {
      return null; // Skip rendering this tab if tabCount is undefined or 0
    }

    return (
      <Button
        key={index}
        style={{
          backgroundColor: selectedTab === tabName ? "#31363f" : "#ffffff",
          color: selectedTab === tabName ? "#ffffff" : "#31363f",
          border: "1px solid #31363f",
        }}
        onClick={() => handleTabChange(tabName)} // Update content based on the tab clicked
      >
        {tabName} ({tabCount})  {/* Display the tab count next to the tab name */}
      </Button>
    );
  })
}


    </div>

      {/* Tabs styled as buttons */}
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <button
          style={{
            padding: "10px 20px",
            backgroundColor: activeTab === "1" ? "#31363f" : "#f0f0f0",
            color: activeTab === "1" ? "#fff" : "#000",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange1("1")}
        >
          Pieces
        </button>
        <button
          style={{
            padding: "10px 20px",
            backgroundColor: activeTab === "2" ? "#31363f" : "#f0f0f0",
            color: activeTab === "2" ? "#fff" : "#000",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange1("2")}
        >
          Looks
        </button>
      </div>
      {displaLinkData && axis === 0 ? (
        <>
          {activeTab === "1" && // Ensure this block only works when activeTab is "1"
            ((UserShoppingCollageData.filter(
              (item) => item.CAPSULE_TYPE === "Pieces"
            ).length < 1 &&
              SelectUserData.status !== 4) ||
              editItem) && (
              <PeiceCollage
                sampleImages={product}
                defaultFrameSize={8}
                SelectUserData={SelectUserData}
                handleSingleCollage={handleTemplateSelectionsubmmit}
                uploadDragImageList={(data) => setuploadDragImageList(data)}
                SendinputText={(data) => {
                  setCOLLAGE_DESCRIPTION(data);
                  // console.log(data, "shopping ....");
                }}
                SendImageLinks={(data) => setproductimagelink(data)}
                sendfileDataList={(data) => setsendfileDataList(data)}
                uploadCreatedCollage={(
                  file,
                  description,
                  fileDataList,
                  fileList
                ) => {
                  const imageId = editItem?.IMAGE_ID || null; // Extract IMAGE_ID from editItem
                  // console.log(
                  //   { editItem, product, imageId },
                  //   "Inside Collage Component"
                  // );
                  uploadCreatedCollage(
                    file,
                    description,
                    fileDataList,
                    fileList,
                    imageId
                  );
                }}
                onTabChange={handleTabChange}
                optionsList={optionsList}
                editItem={editItem}
              />
            )}

          
          {activeTab === "2" && // Separate logic for activeTab === "2"
            ((UserShoppingCollageData.filter(
              (item) => item.CAPSULE_TYPE === "Looks"  && item.EVENT_TYPE === selectedTab
            ).length < tabCountMap[selectedTab] &&
              SelectUserData.status !== 4) ||
              editItem) && (
              <CapsuleWardrobe1
                sampleImages={product}
                defaultFrameSize={requiredLinks ? requiredLinks : 5}
                SelectUserData={SelectUserData}
                handleSingleCollage={handleTemplateSelectionsubmmit}
                uploadDragImageList={(data) => setuploadDragImageList(data)}
                SendinputText={(data) => {
                  setCOLLAGE_DESCRIPTION(data);
                  // console.log(data, "shopping ....");
                }}
                SendImageLinks={(data) => setproductimagelink(data)}
                sendfileDataList={(data) => setsendfileDataList(data)}
                uploadCreatedCollage={(
                  file,
                  description,
                  fileDataList,
                  fileList
                ) => {
                  const imageId = editItem?.IMAGE_ID || null; // Extract IMAGE_ID from editItem
                  // console.log(
                  //   { editItem, product, imageId },
                  //   "Inside Collage Component"
                  // );
                  uploadCreatedCollage(
                    file,
                    description,
                    fileDataList,
                    fileList,
                    imageId
                  );
                }}
                onTabChange={handleTabChange}
                optionsList={optionsList}
                editItem={editItem}
              />
            )}
          {editItem && (
            <div>
              {" "}
              <button
                className="button"
                onClick={() => {
                  handleModalCancel1();
                  setEditItem(null);
                }}
              >
                {" "}
                Close{" "}
              </button>
            </div>
          )}
          {SelectUserData.status === 4 && (
            <>
              <p style={{ textAlignLast: "center" }}>User Service is Closed</p>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <div className="shoppongCollageContainer">
        {dispalProduct && (
          <>
            {" "}
            {/* {loading && product ? (
              <div className="loading">
                <Spin tip="Loading..." style={{ marginTop: "5%" }} />
              </div>
            ) : (
              <>
                {" "}
                <Collage
                  sampleImages={product}
                  defaultFrameSize={requiredLinks ? requiredLinks : 5}
                  SelectUserData={SelectUserData}
                  handleSingleCollage={handleTemplateSelectionsubmmit}
                  uploadCreatedCollage={uploadCreatedCollage}
                  uploadDragImageList={(data) => setuploadDragImageList(data)}
                />
              </>
            )} */}
          </>
        )}

        {UserShoppingCollageData.filter(
          (item) =>
            activeTab === "1"
              ? item.CAPSULE_TYPE === "Pieces"
              : activeTab === "2"
              ? item.CAPSULE_TYPE === "Looks"
              : true // Default to include all items if no activeTab condition is met
        ).length > 0 && (
          <div className="userProfileMain" style={{ marginBottom: "10pxli" }}>
            <h1 className="heading"> Created Collage List</h1>
          </div>
        )}

        <Modal
          title="Edit Confirmation"
          open={isEditModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          okText="Yes"
          cancelText="No"
        >
          <p>Are you sure you want to edit this item?</p>
        </Modal>

        <Modal
          title="Are you sure you want to submit?"
          open={isModalVisible}
          onOk={handleModalOk1} // Proceed with submission when "Yes" is clicked
          onCancel={handleModalCancel1} // Close the modal when "No" is clicked
          okText="Yes"
          cancelText="No"
        >
          <p>Once you submit, it will be sent to the user.</p>
        </Modal>

        <Modal
          title="Delete Confirmation"
          open={isDeleteModalVisible}
          onOk={handleDeleteModalOk}
          onCancel={handleDeleteModalCancel}
          okText="Yes"
          cancelText="No"
        >
          <p>Are you sure you want to delete this item?</p>
        </Modal>

        {axis === 0 && SelectUserData?.status !== 4 ? (
          <Flex direction="row" className="Collagelist" wrap="nowrap">
            {UserShoppingCollageData &&
              UserShoppingCollageData.filter(
                (item) =>
                  activeTab === "1"
                    ? item.CAPSULE_TYPE === "Pieces"
                    : activeTab === "2"
                    ? item.CAPSULE_TYPE === "Looks"
                    : true // Default to show all if no condition is met
              ).map((item, index) => (
                <CollageComponent
                  key={index}
                  count={index + 1}
                  imageUrl={item?.IMAGE_NAME}
                  // Delete={() => {
                  //   handleDelete(item);
                  // }}
                  Delete={() => showDeleteConfirmation(item)}
                  // editItem={() => setEditItem(item)}
                  editItem={() => showEditConfirmation(item)}
                  item={item}
                />
              ))}
          </Flex>
        ) : (
          <div className="onlineProduct">
            {UserShoppingCollageData.filter(
              (item) =>
                activeTab === "1"
                  ? item.CAPSULE_TYPE === "Pieces"
                  : activeTab === "2"
                  ? item.CAPSULE_TYPE === "Looks"
                  : true // Default to include all items if no activeTab condition is met
            ).map((item, index) => (
              <CollageComponent
                key={index}
                count={index + 1}
                imageUrl={item?.IMAGE_NAME}
                // Delete={() => {
                //   handleDelete(item);
                // }}
                item={item}
              />
            ))}
          </div>
        )}
        {SelectUserData?.status === 4 ? (
          <></>
        ) : (
          <>
            {axis === 0 && (
              <Flex className="Shopping-Collage-Bottom-button Shopping-Collage-Header">
               
            
                <Button
                  className="button"
                  disabled={!(UserShoppingCollageData.length === 11 && disableButton)}
                  onClick={handleSubmitButtonClick}
                >
                  Upload Collage
                </Button>
              </Flex>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CapsuleCollage;
