import React from "react";
import "./css/UserDisplayCard.css";
import config from "../../config";
import Profile from "../../images/profile.jpg";
import { timeAgo } from "../../utils/timeAgo";
import { Flex } from "antd";
const UserDisplayCard = ({
  image,
  name,
  dob,
  purchasedate,
  enddate,
  category,
  buttonText,
  background,
  onClick,
  user,
  stylistName,
}) => {
  function extractBaseUrl(url) {
    // Split the URL by "https://" and filter out any empty parts
    const parts = url.split("https://").filter(Boolean);

    // Construct the base URL by joining the last part with "https://"
    return `https://${parts[parts.length - 1]}`;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour time to 12-hour time format
    hours = hours % 12;
    hours = hours ? hours : 12; // handle 00 hour as 12 AM

    return `${day} ${month}, ${year} ${hours}:${minutes}${ampm}`;
  }
  return (
    <div
      className="user-display-card"
      style={{
        background: `${background}`,
        // borderTopRightRadius: `${stylistName ? "0px" : "8px"}`,
      }}
    >
      <div className="first-div">
        <div className="image-container">
          <img
            src={`${
              image ? extractBaseUrl(`${config.imageUrl}${image}`) : Profile
            }`}
            alt="User"
          />
        </div>
        <div className="info-container ">
          <div className="info-item">
            <p style={{ margin: "0" }}> {name ? name : "No Name"}</p>
            {user?.status === 4 && (
              <p style={{ margin: "0" }}> {user?.gender ? user.gender : ""}</p>
            )}
            <p
              style={{
                margin: "0px",
              }}
            >
              {category ? category : ""}
            </p>
            <button className="button-container-button" onClick={onClick}>
              {buttonText}
            </button>
          </div>
          <div className="button-container">
            <div className="info-itemdob">
              <p className="info-itemdob-heading">Purchased Date :</p>
              <p className="info-itemdob-body">
                {purchasedate
                  ? formatDate(purchasedate)
                  : user?.enrollment_date
                  ? formatDate(user?.enrollment_date)
                  : ""}
              </p>
            </div>

            {user?.status === 4 && (
              <div className="info-itemdob">
                <div>Close Service :</div>
                <div className="User-Purc-Date-Div">
                  {enddate
                    ? formatDate(enddate)
                    : user?.end_date
                    ? formatDate(user?.end_date)
                    : ""}
                </div>
              </div>
            )}

            {stylistName && (
              <div className="info-itemdob">
                <p className="info-itemdob-heading">Stylist : </p>
                <p className="info-itemdob-body">
                  {stylistName ? stylistName : ""}
                </p>
              </div>
            )}

            {user && user.USER_SERVICE_ID && (
              <div className="info-itemdob">
                <p className="info-itemdob-heading">Service Id : </p>
                <p className="info-itemdob-body">
                  {user.USER_SERVICE_ID ? user.USER_SERVICE_ID : ""}
                </p>
              </div>
            )}
            {user && user.status === 0 && user.enrollment_date && (
              <div>
                <p className="info-itemdob-body">
                  {user.enrollment_date ? timeAgo(user.enrollment_date) : ""}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDisplayCard;
