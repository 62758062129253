import { initializeApp } from "firebase/app";
import { getToken } from "firebase/messaging";
import { getMessaging,  onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyDvfM3ca7xr_n6w71FCjHO1oecc06xTLXI",
  authDomain: "sskanda-users.firebaseapp.com",
  databaseURL: "https://sskanda-users-default-rtdb.firebaseio.com",
  projectId: "sskanda-users",
  storageBucket: "sskanda-users.appspot.com",
  messagingSenderId: "41092932896",
  appId: "1:41092932896:web:0cbcf8d604e9a42301e345",
  measurementId: "G-KYK98JNJXD",
};
const vapidKey =
  "BDbP6SCHoRyPoqni0yVTZdki6dCQZUSWen3mayJ6vgYR0ra3O_J_FAZ3pvj3J4kOtkxcMHNpRvEHif2Btr7NC1I";
  const app = initializeApp(firebaseConfig);

  const messagingfcm = getMessaging(app);
  export const messaging = getMessaging(app);
  export const requestFCMToken = async () => {
    console.log('requestPermission');
    return Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          console.log('Permission granted');
          return getToken(messagingfcm, { vapidKey });
        } else {
          console.log('Permission denied');
          throw new Error("Notification not granted");
        }
      })
      .catch((err) => {
        console.error("Error getting FCM token: ", err);
        throw err;
      });
  };




  