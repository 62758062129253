import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import { Spin } from "antd";
import QuestionAnswerComponent from "../cards/QuestionAnswerComponent";
import "./css/UserQuestions.css";
const UserQuestions = ({ SelectUserData }) => {
  // //console.log(SelectUserData, "SelectUserData....");
  const [userPersonalQuestionsData, setUserPersonalQuestionsData] = useState(
    []
  );
  const [
    UserPersonalQuestionsBodyFaceTraidData,
    setUserPersonalQuestionsBodyFaceTraidData,
  ] = useState([]);
  const [userShoppingQuestionsData, setUserShoppingQuestionsData] = useState(
    []
  );
  const [UserGoleQuestionsData, setUserGoleQuestionsData] = useState([]);
  const [loadingPersonal, setLoadingPersonal] = useState(true);
  const [loadingShopping, setLoadingShopping] = useState(true);

  // changed here
  let Type;
  switch (SelectUserData?.SERVICE_SHORT_CODE) {
    case "1":
      Type = "Shopping";
      break;
    case "3":
      Type = "Wedding";
      break;
    case "4":
      Type = "Capsule Wardrobe";
      break;
    default:
      Type = "Wardrobe";
  }
  // const Type =
  //   SelectUserData?.service_category === "Online Shopping"
  //     ? "Shopping"
  //     : "Wardrobe";

  const getUserPersonalQuestionsData = async (userId) => {
    //console.log(SelectUserData);
    try {
      const response = await axios.get(
        // `${config.baseURL}userSelectedAnswers/${userId}/PERSONAL`
        `${config.baseURL}getPersonalAssesmentTraid/${userId}/${SelectUserData.gender}`
      );
      if (response?.data?.recordset) {
        setUserPersonalQuestionsData(response.data.recordset);
        //console.log(response.data.recordset);
      }
    } catch (error) {
      console.error("Error fetching personal questions:", error);
    } finally {
      setLoadingPersonal(false);
    }
  };
  const getUserPersonalQuestionsgetBodyFaceTraid = async (userId) => {
    //console.log(SelectUserData, "..........sodsdi");
    try {
      const response = await axios.get(
        // `${config.baseURL}userSelectedAnswers/${userId}/PERSONAL`
        `${config.baseURL}getBodyFaceTraid/${userId}/${SelectUserData.gender}`
      );
      if (response?.data?.recordset) {
        setUserPersonalQuestionsBodyFaceTraidData(response.data.recordset);
        //console.log(response.data, "..........sodsdi");
      }
    } catch (error) {
      console.error("Error fetching personal questions:", error);
    } finally {
      setLoadingPersonal(false);
    }
  };

  const getUserShoppingQuestionsData = async (userId) => {
    try {
      let assistantType;
      // //console.log(SelectUserData?.SERVICE_SHORT_CODE, "SelectUserData?.SERVICE_SHORT_CODE");
      // //console.log(typeof SelectUserData?.SERVICE_SHORT_CODE, SelectUserData?.SERVICE_SHORT_CODE, "hiii jbhbjjbhn");

      switch (SelectUserData?.SERVICE_SHORT_CODE) {
        case "1":
          assistantType = "SHOPPING_ASSISTANT";
          break;
        case "3":
          // assistantType = "WEDDING_ASSISTANCE";
          if (
            SelectUserData?.SELECTED_CATEGORY === "GROOM" ||
            SelectUserData?.SELECTED_CATEGORY === "BRIDE"
          ) {
            assistantType = "WEDDING_GROOM";
          } else {
            assistantType = "WEDDING_ASSISTANCE";
          }
          break;
        case "4":
          assistantType = "CAPSULE_WARDROBE";
          break;
        default:
          assistantType = "WARDROBE_ASSISTANT";
      }

      // //console.log(assistantType, "assistanttype");

      // const endpoint =
      //   SelectUserData?.SERVICE_SHORT_CODE === "Online Shopping"
      //     ? "SHOPPING_ASSISTANT"
      //     : "WARDROBE_ASSISTANT";
      // //console.log(SelectUserData,"SelectUserData......",endpoint)
      const response = await axios.get(
        `${config.baseURL}userSelectedAnswers/${userId}/${assistantType}`
      );
      if (response?.data?.recordset) {
        console.log(response?.data?.recordset,"response?.data?.recordset...sdlkmslm..")
        setUserShoppingQuestionsData(response.data.recordset);
      }
    } catch (error) {
      console.error("Error fetching shopping questions:", error);
    } finally {
      setLoadingShopping(false);
    }
  };
  const getUserGoleQuestionsData = async (userId) => {
    try {
      // //console.log(SelectUserData,"SelectUserData......",endpoint)
      const response = await axios.get(
        `${config.baseURL}getStyleGoal/${userId}`
      );
      if (response?.data?.recordset) {
        //console.log(response?.data?.recordset,"response?.data?.recordset.....setUserGoleQuestionsData")
        setUserGoleQuestionsData(response.data.recordset);
      }
    } catch (error) {
      console.error("Error fetching shopping questions:", error);
    } finally {
      setLoadingShopping(false);
    }
  };

  useEffect(() => {
    if (SelectUserData?.user_id) {
      getUserPersonalQuestionsData(SelectUserData.user_id);
      getUserPersonalQuestionsgetBodyFaceTraid(SelectUserData.user_id);
      getUserShoppingQuestionsData(SelectUserData.user_id);
      getUserGoleQuestionsData(SelectUserData.user_id);
    }
  }, [SelectUserData]);
  function cleanAndParseJSON(jsonString) {
    try {
      // Use a regular expression to remove invalid commas between array elements
      const cleanedJSON = jsonString
        .replace(/,\s*,/g, ",")
        .replace(/,\s*\]/g, "]");

      // Parse the cleaned JSON
      return JSON.parse(cleanedJSON);
    } catch (error) {
      console.error("Invalid JSON:", error);
      return null;
    }
  }
  return (
    <div className="userQuestionMainDiv">
      <div className="userQuestionDiv">
        {/* <h1>Personal Questions</h1> */}
        <div className="userProfileMain">
          <h1 className="heading ">Personal Questions</h1>
        </div>

        {loadingPersonal ? (
          <div className="loading">
            <Spin tip="Loading..." style={{ marginTop: "5%" }} />
          </div>
        ) : userPersonalQuestionsData.length > 0 ? (
          // userPersonalQuestionsData.map((user, index) => (
          <>
            <QuestionAnswerComponent
              key={1}
              question={"Personal Assessment"}
              answer={userPersonalQuestionsData[0]?.PERSONAL_ASSESSMENT_VALUE}
            />
            {/* <QuestionAnswerComponent
              key={1}
              question={"Personal Traid"}
              answer={userPersonalQuestionsData[0]?.REPORT_DESCRIPTION}
            /> */}
            <div className="question-answer-container">
              <div className="question">Personality Traits</div>
              <div className="answer">
                {/* {userPersonalQuestionsData[0]?.REPORT_DESCRIPTION &&
                  JSON.parse(
                    userPersonalQuestionsData[0].REPORT_DESCRIPTION
                  )?.description.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))} */}

                {userPersonalQuestionsData[0]?.REPORT_DESCRIPTION &&
                  cleanAndParseJSON(
                    userPersonalQuestionsData[0]?.REPORT_DESCRIPTION
                  ).description.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
              </div>
            </div>
            <QuestionAnswerComponent
              key={1}
              question={"Personality Trait Image"}
              answer={userPersonalQuestionsData[0]?.REPORT_IMAGE}
            />
            <QuestionAnswerComponent
              key={1}
              question={"This is their Style Goal:"}
              answer={UserGoleQuestionsData[0]?.USER_ANSWERS}
            />
            <QuestionAnswerComponent
              key={1}
              question={"This is their Body Shape:"}
              answer={
                UserPersonalQuestionsBodyFaceTraidData[0]?.BODY_SHAPE_VALUE
              }
            />
            <QuestionAnswerComponent
              key={1}
              question={"Body Shape Characteristics"}
              answer={
                UserPersonalQuestionsBodyFaceTraidData[0]
                  ?.REPORT_CHARACTERISTICS
              }
            />
            <div className="question-answer-container">
              <div className="question">Body Shape Description</div>
              <div className="answer">
                {UserPersonalQuestionsBodyFaceTraidData[0]
                  ?.REPORT_DESCRIPTION &&
                  Object.entries(
                    UserPersonalQuestionsBodyFaceTraidData[0]
                      ?.REPORT_DESCRIPTION &&
                      JSON.parse(
                        UserPersonalQuestionsBodyFaceTraidData[0]
                          ?.REPORT_DESCRIPTION
                      )
                  ).map((item, index) => <li key={index}>{item}</li>)}
              </div>
            </div>
            <QuestionAnswerComponent
              key={1}
              question={"Body Shape Image"}
              answer={UserPersonalQuestionsBodyFaceTraidData[0]?.REPORT_IMAGE}
            />
            <QuestionAnswerComponent
              key={1}
              question={"This is their Face Shape:"}
              answer={
                UserPersonalQuestionsBodyFaceTraidData[0]?.FACE_SHAPE_VALUE
              }
            />
            <QuestionAnswerComponent
              key={1}
              question={"Facial Recognition Video"}
              answer={UserPersonalQuestionsBodyFaceTraidData[0]?.FACE_CAMERA}
            />
            <QuestionAnswerComponent
              key={1}
              question={"Full Body Image"}
              answer={
                UserPersonalQuestionsBodyFaceTraidData[0]?.FULL_BODY_IMAGE
              }
            />
          </>
        ) : (
          // ))
          <p>No personal questions found.</p>
        )}
      </div>
      <div className="userQuestionDiv">
        <div className="userProfileMain">
          <h1 className="heading">{Type} Assistant Questions</h1>
        </div>
        {loadingShopping ? (
          <div className="loading">
            <Spin tip="Loading..." style={{ marginTop: "5%" }} />
          </div>
        ) : userShoppingQuestionsData.length > 0 ? (
          userShoppingQuestionsData.map((user, index) => (
            <QuestionAnswerComponent
              key={index}
              question={user?.QUESTION}
              answer={user?.USER_ANSWERS}
            />
          ))
        ) : (
          <p>No {Type} questions found.</p>
        )}
      </div>
    </div>
  );
};

export default UserQuestions;
